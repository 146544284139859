exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliatedisclosure-js": () => import("./../../../src/pages/affiliatedisclosure.js" /* webpackChunkName: "component---src-pages-affiliatedisclosure-js" */),
  "component---src-pages-discounted-products-automotive-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/automotive-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-automotive-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-computer-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/computer-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-computer-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-electronic-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/electronic-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-electronic-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-industrial-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/industrial-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-industrial-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-luggage-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/luggage-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-luggage-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-mobile-and-accessories-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/Mobile-And-Accessories-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-mobile-and-accessories-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-office-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/office-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-office-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-sports-and-outdoor-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/sports-and-outdoor-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-sports-and-outdoor-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-tools-and-home-improvement-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/tools-and-home-improvement-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-tools-and-home-improvement-gadgets-on-amazon-js" */),
  "component---src-pages-discounted-products-watch-gadgets-on-amazon-js": () => import("./../../../src/pages/discounted-products/watch-gadgets-on-amazon.js" /* webpackChunkName: "component---src-pages-discounted-products-watch-gadgets-on-amazon-js" */),
  "component---src-pages-gadgets-js": () => import("./../../../src/pages/gadgets.js" /* webpackChunkName: "component---src-pages-gadgets-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-promos-js": () => import("./../../../src/pages/promos.js" /* webpackChunkName: "component---src-pages-promos-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-templates-blog-js-content-file-path-content-blog-affordable-action-camera-for-adventures-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/affordable-action-camera-for-adventures/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-affordable-action-camera-for-adventures-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-amazon-wireless-earbuds-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/amazon-wireless-earbuds/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-amazon-wireless-earbuds-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-best-dash-cam-for-live-streaming-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/Best-dash-cam-for-live-streaming/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-best-dash-cam-for-live-streaming-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-best-m-2-nvme-ssd-with-1-tb-storage-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/best-m2-nvme-ssd-with-1tb-storage/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-best-m-2-nvme-ssd-with-1-tb-storage-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-best-power-strips-with-surge-protection-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/Best-power-strips-with-surge-protection/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-best-power-strips-with-surge-protection-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-best-selling-mini-projectors-on-amazon-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/Best-selling-mini-projectors-on-amazon/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-best-selling-mini-projectors-on-amazon-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-best-smartwatch-on-a-budget-affordable-and-feature-packed-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/best-smartwatch-on-a-budget-affordable-and-feature-packed/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-best-smartwatch-on-a-budget-affordable-and-feature-packed-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-connecting-bluetooth-headphones-to-xbox-and-nintendo-switch-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/Connecting-bluetooth-headphones-to-xbox-and-nintendo-switch/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-connecting-bluetooth-headphones-to-xbox-and-nintendo-switch-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-gaming-keyboards-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/gaming-keyboards/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-gaming-keyboards-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-gaming-laptops-for-best-gaming-experience-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/gaming-laptops-for-best-gaming-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-gaming-laptops-for-best-gaming-experience-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-guide-choosing-the-best-kindle-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/guide-choosing-the-best-kindle/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-guide-choosing-the-best-kindle-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-highly-rated-power-adapters-on-amazon-for-travelers-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/Highly-Rated-Power-Adapters-On-Amazon-For-Travelers/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-highly-rated-power-adapters-on-amazon-for-travelers-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-indoor-security-camera-available-on-amazon-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/indoor-security-camera-available-on-amazon/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-indoor-security-camera-available-on-amazon-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-low-budget-affordable-drones-for-the-ultimate-adventure-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/low-budget-affordable-drones-for-the-ultimate-adventure/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-low-budget-affordable-drones-for-the-ultimate-adventure-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-mesh-wifi-system-on-amazon-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/mesh-wifi-system-on-amazon/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-mesh-wifi-system-on-amazon-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-the-best-charging-stand-for-phone-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/The-best-charging-stand-for-phone/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-the-best-charging-stand-for-phone-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-the-best-portable-bluetooth-speakers-for-your-next-outdoor-adventure-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/The-Best-Portable-Bluetooth-Speakers-for-Your-Next-Outdoor-Adventure/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-the-best-portable-bluetooth-speakers-for-your-next-outdoor-adventure-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-the-best-travel-backpack-with-top-reviews-on-amazon-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/The-Best-Travel-Backpack-with-top-reviews-on-Amazon/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-the-best-travel-backpack-with-top-reviews-on-amazon-index-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-top-portable-power-stations-on-amazon-index-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/Users/nilay/Sites/Sites/gadgetvital/content/blog/Top-portable-power-stations-on-amazon/index.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-top-portable-power-stations-on-amazon-index-mdx" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */)
}

